import React, { ChangeEvent } from 'react';
import {
  Box,
  Heading,
  FormControl,
  Select,
  Stack,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import LayerToggles from 'LayerToggles';
import ScoreMapSettings from 'ScoreMapSettings';
import HeatMapSettings from 'HeatMapSettings';
import { ReactComponent as Logo } from './neon-logo.svg';

const MainPanel: React.FC = () => {
  const ctx = useApplicationContext();
  const handlePickMap = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case 'heatmap':
      case 'sample':
      case 'transactions':
      case 'score':
        ctx.setMapType(e.target.value);
        break;
      default:
        ctx.setMapType(undefined);
    }
  };
  const isScoreMap = ctx.mapType === 'score';
  const isHeatMap = ctx.mapType === 'heatmap';
  return (
    <Flex direction="column" justifyContent="space-between" height="100%">
      <Stack direction="column" spacing={8}>
        <LayerToggles />
        <Box>
          <FormControl>
            <Heading size="md" color="white" mb={2}>
              Heatmap Overlay
            </Heading>
            <Select onChange={handlePickMap}>
              <option value="">None</option>
              <option value="heatmap">User Heatmap</option>
              <option value="score">Score Heatmap</option>
              <option value="transactions">Dutchie Transactions</option>
            </Select>
          </FormControl>
          {isScoreMap && (
            <Box mt={4}>
              <ScoreMapSettings />
            </Box>
          )}
          {isHeatMap && (
            <Box mt={4}>
              <HeatMapSettings />
            </Box>
          )}
        </Box>
      </Stack>
      <Flex pt={4} color="white" alignItems="flex-end">
        <Box as={Logo} width="100px" />
        <Text
          fontFamily="mono"
          fontWeight="semibold"
          letterSpacing="5px"
          fontSize="md"
          lineHeight={1.25}
          ml={3}
          color="gray.400"
        >
          PREDICT
        </Text>
      </Flex>
    </Flex>
  );
};

export default observer(MainPanel);

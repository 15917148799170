/* eslint-disable class-methods-use-this */
import firebase from 'firebase/app';
import 'firebase/firestore';
import { action, makeObservable, observable } from 'mobx';
import { stores } from 'stores';

export class Location {
  name: string;

  latitude: number;

  longitude: number;

  zoom? = 10;

  storeCode?: string;

  constructor(data: firebase.firestore.DocumentData) {
    this.name = data.name;
    this.latitude = data.lat;
    this.longitude = data.lng;
    this.zoom = data.zoom;
    this.storeCode = data.storeCode;
  }

  toFirestore() {
    const d: firebase.firestore.DocumentData = {
      name: this.name,
      lat: this.latitude,
      lng: this.longitude,
      zoom: this.zoom || 10,
    };
    if (this.storeCode) {
      d.storeCode = this.storeCode;
    }
    return d;
  }
}

class LocationConverter {
  toFirestore(location: Location) {
    return location.toFirestore();
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Location {
    const data = snapshot.data(options);
    return new Location(data);
  }
}

class LocationCollection {
  db = firebase.firestore();

  init = false;

  _snapshot: firebase.firestore.QuerySnapshot<Location> | null;

  _collectionRef: firebase.firestore.CollectionReference<Location>;

  _query: firebase.firestore.Query<Location>;

  get docs() {
    return this._snapshot?.docs;
  }

  add(location: Location) {
    return this._collectionRef.add(location);
  }

  constructor() {
    makeObservable(this, {
      _snapshot: observable,
      _collectionRef: observable,
    });
    this._snapshot = null;
    this._collectionRef = this.db
      .collection('locations')
      .withConverter(new LocationConverter());
    this._query = this._collectionRef.orderBy('name');
    this._query.onSnapshot((s) => {
      action(() => {
        this._snapshot = s;
      })();
    });
  }
}

export class AppStore {
  db = firebase.firestore();

  _locations?: LocationCollection;

  get locations() {
    if (!this._locations) {
      this._locations = new LocationCollection();
    }

    return this._locations;
  }

  createLocation(
    name: string,
    lat: number,
    lng: number,
    zoom = 10,
    storeCode?: string
  ) {
    if (lat && lng) {
      const location = new Location({
        name,

        lat,
        lng,
        zoom,
        storeCode,
      });
      return this.locations.add(location);
    }
  }
}

declare global {
  interface Window {
    uploadStores: () => void;
  }
}

window.uploadStores = () => {
  const appStore = new AppStore();
  stores.forEach((s) => {
    appStore.createLocation(s.name, s.lat, s.lng, 12, s.storeCode);
  });
};

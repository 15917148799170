import { useCallback, useState } from 'react';
import { FeatureCollection } from 'geojson';
import axios from 'axios';

export function useMapData(fnName: string) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FeatureCollection | undefined>(undefined);
  const url = `https://us-central1-predictor-78c77.cloudfunctions.net/data/${fnName}`;
  const getData = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(url);

    setData(response.data as FeatureCollection);
    setLoading(false);
    return response.data;
  }, [url]);

  return {
    loading,
    getData,
    data,
    url,
  };
}

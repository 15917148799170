/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Flex, IconButton, Link, Text, Heading } from '@chakra-ui/react';
import { useApplicationContext, useAppStore } from 'AppContextProvider';
import { Location } from 'appStore';
import { observer } from 'mobx-react';
import { DeleteIcon } from '@chakra-ui/icons';
import ExportReport from './ExportReport';

function useFlyTo() {
  const ctx = useApplicationContext();

  return (l: Location) => {
    return () => {
      ctx.flyTo(l);
    };
  };
}

const Navigate: React.FC = () => {
  const flyTo = useFlyTo();
  const appStore = useAppStore();
  const { docs } = appStore.locations;

  if (!docs) {
    return null;
  }

  const locations = docs.sort().map((d) => {
    const location = d.data();
    return (
      <Box key={d.id} display="flex" my={2}>
        <Box flex="1">
          <Link onClick={flyTo(location)}>{location.name}</Link>
        </Box>
        <IconButton
          size="xs"
          aria-label="Delete Location"
          variant="ghost"
          color="white"
          _hover={{
            bgColor: 'red.500',
          }}
          onClick={() => {
            d.ref.delete();
          }}
          icon={<DeleteIcon />}
        />
      </Box>
    );
  });

  return (
    <Box>
      <Box mb={8}>
        <Heading size="md" color="white" mb={4}>
          Add a Candidate Location
        </Heading>

        <Text>Click any spot on the map to add a location marker.</Text>
      </Box>

      <Flex justifyContent="space-between" alignItems="baseline" mb={4}>
        <Heading size="md" color="white">
          Saved Locations
        </Heading>
        <ExportReport />
      </Flex>
      {locations.length > 0 ? (
        <Box>{locations}</Box>
      ) : (
        <Box>No locations have been saved to the map.</Box>
      )}
    </Box>
  );
};

export default observer(Navigate);

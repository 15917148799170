import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useApplicationContext, AppContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import { dataCache } from './DataCachingLayer';

const MAX_ZOOM_LEVEL = 18;

const heatmapLayer = (ctx: AppContext): LayerProps => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  beforeId: 'fake-layer-1',
  id: 'heatmap',
  layout: {
    visibility: ctx.mapType === 'heatmap' ? 'visible' : 'none',
  },
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['cubic-bezier', 0.32, 0, 0.67, 0],
      ['get', ctx.viewOptions.dataField || 'p'],
      1,
      0.01,
      10,
      0.1,
      1000,
      0.3,
      5000,
      1,
    ],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': [
      'interpolate',
      ['cubic-bezier', 0.32, 0, 0.67, 0],
      ['zoom'],
      6,
      1,
      12,
      5,
    ],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      ctx.viewOptions.colourHeatMap1,
      0.01,
      ctx.viewOptions.colourHeatMap2,
      0.1,
      ctx.viewOptions.colourHeatMap3,
      1.0,
      ctx.viewOptions.colourHeatMap5,
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['cubic-bezier', 0.32, 0, 0.67, 0],
      ['zoom'],
      4,
      0.5,
      6,
      1,
      10,
      5,
      13,
      18,
      15,
      25,
    ],
    // Transition from heatmap to circle layer by zoom level
    // 'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 16, 0],
  },
});

const MapHeatMap: React.FC = () => {
  const ctx = useApplicationContext();
  const { viewOptions } = ctx;
  if (!viewOptions.month) {
    return null;
  }
  const { allHours } = viewOptions;

  const day =
    viewOptions.month.substr(0, 8) + ('0' + viewOptions.day).substr(-2);
  const layerProps = heatmapLayer(ctx);

  let filter = ['!=', 'dt', -1];
  if (!viewOptions.allDays) {
    filter = ['==', 'dt', parseInt(viewOptions.day, 10)];
    if (!viewOptions.allHours) {
      filter = ['==', 'h', viewOptions.hour];
    }
  }

  const data = dataCache.data(
    allHours ? `month/${viewOptions.month}` : `day/${day}`,
    ctx.locationQuery
  );

  return (
    <Source type="geojson" data={data}>
      <Layer {...layerProps} filter={filter} />
    </Source>
  );
};
export default observer(MapHeatMap);

import React from 'react';
import {
  Box,
  Flex,
  Icon,
  IconProps,
  Spinner,
  Text,
  Tab,
  TabProps,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import PointDetails from 'PointDetails';
import { useApplicationContext } from 'AppContextProvider';
import { action } from 'mobx';
import Colours from 'Colours';
import MainPanel from 'MainPanel';
import Locations from 'Locations';
import { HiLocationMarker, HiMenuAlt2, HiCog, HiMap } from 'react-icons/hi';
import { dataCache } from 'DataCachingLayer';

export const TAB_DETAILS = 2;
export const TAB_LOCATIONS = 1;
const PANEL_WIDTH = 350;

const StyledTab: React.FC<TabProps> = (props) => (
  <Tab
    flexDirection="column"
    height={16}
    bgColor="whiteAlpha.100"
    fontSize="xs"
    fontWeight="semibold"
    transition="background-color 0.15s linear"
    _hover={{
      bgColor: 'whiteAlpha.200',
    }}
    _focus={{
      boxShadow: 'none',
      bgColor: 'whiteAlpha.225',
      _hover: {
        bgColor: 'whiteAlpha.225',
      },
    }}
    {...props}
  />
);

interface TabIconProps extends IconProps {
  as?: React.ElementType;
}

const TabIcon: React.FC<TabIconProps> = (props) => (
  <Icon boxSize={6} mb="2px" {...props} />
);

const StyledTabPanel: React.FC<TabPanelProps> = (props) => (
  <TabPanel pt={6} pb={8} px={6} {...props} />
);

interface NotificationProps {
  isOpen: boolean;
}

const Notification: React.FC<NotificationProps> = ({ isOpen }) => {
  return (
    <Box position="fixed" right={4} bottom={4} pointerEvents="none">
      <Flex
        alignItems="center"
        transition="transform 0.3s ease-out"
        transform={`translateY(${isOpen ? 0 : 'calc(100% + 16px)'})`}
        width={`${PANEL_WIDTH - 32}px`}
        borderRadius="md"
        bgColor="pink.500"
        color="white"
        p={3}
      >
        <Spinner size="md" mr={3} />
        Updating map
      </Flex>
    </Box>
  );
};

const DetailsPanel: React.FC = () => {
  const { selected } = useApplicationContext();

  return (
    <Box
      flex="none"
      display="block"
      background="#030007"
      color="gray.400"
      width={`${PANEL_WIDTH}px`}
      height="100vh"
      zIndex="1111"
    >
      <Tabs
        display="flex"
        flexDirection="column"
        height="100%"
        isFitted
        index={selected.tab}
        colorScheme="lightblue"
        onChange={action((idx: number) => {
          selected.tab = idx;
        })}
      >
        <TabList
          flex="none"
          borderBottom={0}
          m={2}
          borderRadius="md"
          overflow="hidden"
        >
          <StyledTab>
            <TabIcon as={HiMap} />
            <Text>Overlays</Text>
          </StyledTab>
          <StyledTab>
            <TabIcon as={HiLocationMarker} />
            <Text>Locations</Text>
          </StyledTab>
          <StyledTab>
            <TabIcon as={HiMenuAlt2} />
            <Text>Reports</Text>
          </StyledTab>
          <StyledTab>
            <TabIcon as={HiCog} />
            <Text>Settings</Text>
          </StyledTab>
        </TabList>
        <Box overflowY="auto" height="inherit">
          <TabPanels height="inherit">
            <StyledTabPanel height="inherit">
              <MainPanel />
            </StyledTabPanel>
            <StyledTabPanel>
              <Locations />
            </StyledTabPanel>
            <StyledTabPanel>
              <PointDetails />
            </StyledTabPanel>
            <StyledTabPanel>
              <Colours />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </Tabs>

      <Notification isOpen={dataCache.loading} />
    </Box>
  );
};

export default observer(DetailsPanel);

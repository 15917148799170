import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { useTheme } from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import { TAB_DETAILS } from 'DetailsPanel';
import { observer } from 'mobx-react';
import { createGeoJSONCircle } from './util';

const RadiusMarker: React.FC = () => {
  const { selected } = useApplicationContext();
  const theme = useTheme();
  const showRadius = selected.tab === TAB_DETAILS;

  return (
    <Source
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: createGeoJSONCircle(
              selected.lat,
              selected.lng,
              selected.radius / 1000
            ),

            properties: [],
          },
        ],
      }}
    >
      <Layer
        type="fill"
        paint={{
          'fill-color': theme.colors.green[300],
          'fill-opacity': showRadius ? 0.3 : 0,
        }}
      />
    </Source>
  );
};

export default observer(RadiusMarker);

import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useApplicationContext, AppContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import { dataCache } from './DataCachingLayer';

const MAX_ZOOM_LEVEL = 17;
const CHANGE_GEOHASH_AT_ZOOM_LEVEL = 13;

const heatmapLayer = (ctx: AppContext): LayerProps => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  beforeId: 'fake-layer-1',
  layout: {
    visibility: ctx.mapType === 'score' ? 'visible' : 'none',
  },
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['cubic-bezier', 0.32, 0, 0.67, 0],
      ['get', 'p'],
      0,
      0,
      50,
      1,
    ],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      0.1,
      8,
      0.3,
      13,
      1,
      18,
      2,
    ],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      ctx.viewOptions.colourHeatMap1,
      0.25,
      ctx.viewOptions.colourHeatMap2,
      0.5,
      ctx.viewOptions.colourHeatMap3,
      0.75,
      ctx.viewOptions.colourHeatMap4,
      1.0,
      ctx.viewOptions.colourHeatMap5,
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      25,
      11,
      25,
      12,
      30,
      13,
      65,
      14,
      125,
      15,
      200,
      16,
      300,
      18,
      1000,
    ],
    // Transition from heatmap to circle layer by zoom level
    // 'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 16, 0],
  },
});

const ScoreHeatMap: React.FC = () => {
  const ctx = useApplicationContext();
  const { lat, monthIndex } = ctx.viewOptions;
  const { months } = ctx;

  if (lat === 0) {
    return null;
  }

  const layerProps = heatmapLayer(ctx);
  const month = months[monthIndex];
  if (!month) {
    return null;
  }

  const data = dataCache.data('scoregrid', ctx.locationQuery, {
    zoomRequeryAreaThreshold: 0.005,
    geohashLevel:
      ctx.viewport?.zoom && ctx.viewport.zoom <= CHANGE_GEOHASH_AT_ZOOM_LEVEL
        ? 6
        : 7,
  });
  return (
    <Source type="geojson" data={data} id="scoregrid">
      <Layer {...layerProps} filter={['==', 'm', month]} id="scoremap" />
    </Source>
  );
};

export default observer(ScoreHeatMap);

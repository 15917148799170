import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useApplicationContext, AppContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import { dataCache } from './DataCachingLayer';

const MAX_ZOOM_LEVEL = 18;
const WEIGHT = 2;
const RADIUS = 10;
const heatmapLayer = (
  ctx: AppContext,
  endColor: string,
  startColor: string,
  isVisible = false
): LayerProps => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  beforeId: 'fake-layer-2',
  layout: {
    visibility: isVisible ? 'visible' : 'none',
  },
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'p'],
      0,
      0,
      WEIGHT,
      1,
    ],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      1,
      MAX_ZOOM_LEVEL,
      ctx.viewOptions.heatmapIntensity,
    ],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(8, 8, 200,0)',
      0.1,
      startColor,
      0.8,
      endColor,
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      2,
      MAX_ZOOM_LEVEL,
      RADIUS,
    ],
    // Transition from heatmap to circle layer by zoom level
    // 'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 16, 0],
  },
});

const DeviceLocationMap: React.FC = () => {
  const ctx = useApplicationContext();
  const { showHome, showWork } = ctx.viewOptions;
  const homeLayerProps = heatmapLayer(
    ctx,
    ctx.viewOptions.homeMarkerHigh,
    ctx.viewOptions.homeMarkerLow,
    showHome
  );

  const workLayerProps = heatmapLayer(
    ctx,
    ctx.viewOptions.workMarkerHigh,
    ctx.viewOptions.workMarkerLow,
    showWork
  );

  if (!ctx.viewOptions.minLat || ctx.viewOptions.minLat === 0) {
    return null;
  }

  const data = dataCache.data('locations', ctx.locationQuery);
  return (
    <Source type="geojson" data={data} id="deviceLocations">
      <Layer {...workLayerProps} filter={['==', 't', showWork && 'work']} />
      <Layer {...homeLayerProps} filter={['==', 't', showHome && 'home']} />
    </Source>
  );
};
export default observer(DeviceLocationMap);

import React from 'react';
import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import SimpleSlider from 'SimpleSlider';

import { action } from 'mobx';

const ScoreMapSettings: React.FC = () => {
  const { viewOptions, months } = useApplicationContext();

  const handleDaySlider = action((v: number) => {
    viewOptions.monthIndex = v;
  });

  return (
    <FormControl>
      <FormLabel color="white" fontWeight="bold" mb={2}>
        Month
      </FormLabel>
      <SimpleSlider
        mb={2}
        min={0}
        max={months.length - 1}
        defaultValue={0}
        onChange={handleDaySlider}
      />
      <Text>
        {format(new Date(months[viewOptions.monthIndex]), 'MMMM yyyy')}
      </Text>
      {/* <Text>Hour</Text>
        <Checkbox name="allHours" defaultIsChecked>
          All Hours
        </Checkbox>
        <SimpleSlider min={0} max={23} defaultValue={1} display="31" /> */}
    </FormControl>
  );
};

export default observer(ScoreMapSettings);

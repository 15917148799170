import firebase from 'firebase/app';
import 'firebase/auth';
import { action, makeObservable, observable } from 'mobx';

export class AuthStore {
  private auth;

  init = false;

  name?: string;

  pending = false;

  currentUser?: firebase.User | null;

  redirectOnSignIn?: string;

  onSignIn?: (user: firebase.User) => void;

  onSignOut?: () => void;

  constructor() {
    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.signInWithGoogle = this.signInWithGoogle.bind(this);
    this.setIsInit = this.setIsInit.bind(this);

    makeObservable(this, {
      init: observable,
      name: observable,
      pending: observable,
      currentUser: observable,
      signInWithGoogle: action,
      handleAuthChange: action,
      setIsInit: action,
    });

    this.auth = firebase.auth();
    this.currentUser = this.auth.currentUser;
    this.pending = true;

    this.auth.onAuthStateChanged(this.handleAuthChange);
  }

  async signInWithGoogle(): Promise<void> {
    if (this.currentUser) {
      return;
    }
    this.pending = true;
    await this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    await this.auth.signInWithPopup(provider);
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  setIsInit() {
    this.init = true;
  }

  handleAuthChange(user: firebase.User | null) {
    this.pending = false;
    const wasSignedIn = !!this.currentUser;
    this.currentUser = user;
    // User signed in, there is a display name
    if (user?.displayName) {
      this.name = user.displayName;
      if (this.onSignIn) {
        this.onSignIn(user);
      }
    }
    // User signed out.
    if (!user) {
      this.name = undefined;
      if (wasSignedIn && this.onSignOut) {
        this.onSignOut();
      }
    } else {
      this.setIsInit();
    }
  }
}

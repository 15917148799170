import React, { useEffect, useRef, useState } from 'react';
import {
  Stack,
  Checkbox,
  IconButton,
  Select,
  FormControl,
  FormLabel,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import add from 'date-fns/add';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { useForm, Controller } from 'react-hook-form';
import { IoMdPlay, IoMdPause } from 'react-icons/io';
import { useApplicationContext } from './AppContextProvider';
import SimpleSlider from './SimpleSlider';

const HEATMAP_TIMELINE_INTERVAL = 500;

interface HeatMapSettingsForm {
  month?: string;
  dataField?: string;
  allDays: boolean;
  allHours: boolean;
  day: number;
  hour: number;
}

const HeatMapSettings: React.FC = () => {
  const playingDaysIntervalRef = useRef<number>(0);
  const playingHoursIntervalRef = useRef<number>(0);
  const { register, watch, setValue, getValues, control } = useForm<
    HeatMapSettingsForm
  >({
    defaultValues: {
      allDays: true,
      day: 1,
      allHours: true,
      hour: 0,
    },
  });
  const { viewOptions, months } = useApplicationContext();
  const [isPlayingDays, setIsPlayingDays] = useState(false);
  const [isPlayingHours, setIsPlayingHours] = useState(false);
  const { dataField, month, allDays, day, hour, allHours } = watch();

  useEffect(() => {
    const updateOptions = () => {
      viewOptions.dataField = dataField;
      viewOptions.month = month || '';
      viewOptions.day = Number(day ?? 1).toString();
      viewOptions.allDays = allDays;
      // Form fields that are not rendered have an undefined value.
      if (hour !== undefined) {
        viewOptions.hour = hour;
      }
      if (allHours !== undefined) {
        viewOptions.allHours = allHours;
      }
    };

    action(updateOptions)();
  }, [viewOptions, dataField, month, day, hour, allDays, allHours]);

  const numDaysInSelectedMonth = month
    ? parseInt(format(lastDayOfMonth(new Date(`${month}T00:00:00`)), 'd'), 10)
    : 0;

  const handlePlayDaysClick = () => {
    if (playingDaysIntervalRef.current) {
      window.clearInterval(playingDaysIntervalRef.current);
    }

    if (!isPlayingDays) {
      setIsPlayingDays(true);
      playingDaysIntervalRef.current = window.setInterval(() => {
        const nextDay = getValues('day') + 1;
        if (nextDay <= numDaysInSelectedMonth) {
          setValue('day', nextDay);
        } else {
          window.clearInterval(playingDaysIntervalRef.current);
          setIsPlayingDays(false);
        }
      }, HEATMAP_TIMELINE_INTERVAL);
    } else {
      setIsPlayingDays(false);
    }
  };

  const handlePlayHoursClick = () => {
    if (playingHoursIntervalRef.current) {
      window.clearInterval(playingHoursIntervalRef.current);
    }

    if (!isPlayingHours) {
      setIsPlayingHours(true);
      playingHoursIntervalRef.current = window.setInterval(() => {
        const nextHour = getValues('hour') + 1;
        if (nextHour < 24) {
          setValue('hour', nextHour);
        } else {
          window.clearInterval(playingHoursIntervalRef.current);
          setIsPlayingHours(false);
        }
      }, HEATMAP_TIMELINE_INTERVAL);
    } else {
      setIsPlayingHours(false);
    }
  };

  return (
    <Stack spacing={4} as="form">
      <FormControl>
        <FormLabel color="white" fontWeight="bold">
          Dataset
        </FormLabel>
        <Select ref={register} name="dataField">
          <option value="p">Total Ping</option>
          <option value="d">Devices</option>
          <option value="cp">Cannabis Ping</option>
          <option value="cd">Cannabis Devices</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel color="white" fontWeight="bold">
          Month
        </FormLabel>
        <Select ref={register} name="month">
          <option value="">Select Month</option>
          {months.map((m) => (
            <option key={m} value={m}>
              {format(new Date(`${m}T00:00:00`), 'MMMM yyyy')}
            </option>
          ))}
        </Select>
      </FormControl>

      {month && (
        <>
          <FormControl>
            <FormLabel color="white" fontWeight="bold" mb={2}>
              Day
            </FormLabel>
            <Checkbox ref={register} name="allDays">
              All days
            </Checkbox>
            {!viewOptions.allDays && (
              <Flex alignItems="center" mt={2}>
                <IconButton
                  colorScheme="lightblue"
                  variant="solid"
                  aria-label={isPlayingDays ? 'Pause' : 'Play'}
                  icon={isPlayingDays ? <IoMdPause /> : <IoMdPlay />}
                  size="sm"
                  mr={4}
                  onClick={handlePlayDaysClick}
                />
                <Box flex={1}>
                  <Controller
                    control={control}
                    name="day"
                    render={(fieldProps) => {
                      return (
                        <SimpleSlider
                          min={1}
                          max={numDaysInSelectedMonth}
                          {...fieldProps}
                        />
                      );
                    }}
                  />
                  <Text>
                    {format(
                      add(new Date(`${month}T00:00:00`), { days: day - 1 }),
                      'MMMM d, yyyy'
                    )}
                  </Text>
                </Box>
              </Flex>
            )}
          </FormControl>

          {!viewOptions.allDays && (
            <FormControl>
              <FormLabel color="white" fontWeight="bold" mb={2}>
                Hour
              </FormLabel>
              <Checkbox name="allHours" ref={register}>
                All hours
              </Checkbox>

              {viewOptions.allHours === false && (
                <Flex alignItems="center" mt={2}>
                  <IconButton
                    colorScheme="lightblue"
                    variant="solid"
                    aria-label={isPlayingHours ? 'Pause' : 'Play'}
                    icon={isPlayingHours ? <IoMdPause /> : <IoMdPlay />}
                    size="sm"
                    mr={4}
                    onClick={handlePlayHoursClick}
                  />
                  <Box flex={1}>
                    <Controller
                      control={control}
                      name="hour"
                      render={(fieldProps) => {
                        return (
                          <SimpleSlider min={0} max={23} {...fieldProps} />
                        );
                      }}
                    />
                    <Text>
                      {format(
                        add(new Date(`${month}T00:00:00`), {
                          hours: hour ?? 0,
                        }),
                        'h:mm a'
                      )}
                    </Text>
                  </Box>
                </Flex>
              )}
            </FormControl>
          )}
        </>
      )}
    </Stack>
  );
};

export default observer(HeatMapSettings);

import { Box, Button } from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import React from 'react';

const Login: React.FC = () => {
  const { authStore } = useApplicationContext();

  return (
    <Box m={16}>
      <Button onClick={() => authStore.signInWithGoogle()}>
        Login to continue
      </Button>
    </Box>
  );
};

export default Login;

import React from 'react';
import './App.css';
import Map from 'Map';
import { useApplicationContext } from 'AppContextProvider';
import Login from 'Login';
import { observer } from 'mobx-react';

function App() {
  const { authStore } = useApplicationContext();

  if (authStore.pending) {
    return null;
  }
  if (!authStore.currentUser) {
    return <Login />;
  }
  return <Map />;
}

export default observer(App);

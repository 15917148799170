import React from 'react';
import { observer } from 'mobx-react';
import { Popup, Source, Layer } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import { useTheme } from '@chakra-ui/react';
import { useApplicationContext, useAppStore } from 'AppContextProvider';
import { TAB_LOCATIONS } from 'DetailsPanel';
import LocationForm from './LocationForm';

const LocationsLayer: React.FC = () => {
  const { locations } = useAppStore();
  const { selected, unsetSelected, viewOptions } = useApplicationContext();
  const theme = useTheme();
  if (!locations.docs) {
    return null;
  }

  const sourceData: FeatureCollection = {
    type: 'FeatureCollection',
    features: locations.docs.map((l) => {
      const data = l.data();
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [data.longitude, data.latitude],
        },
        properties: data,
      };
    }),
  };

  return (
    <>
      <Source id="candidate-locations" type="geojson" data={sourceData}>
        <Layer
          type="circle"
          layout={{
            visibility: viewOptions.showCandidateLocations ? 'visible' : 'none',
          }}
          maxzoom={20}
          paint={{
            'circle-color': theme.colors.orange[500],
            'circle-radius': 5,
            'circle-opacity': 0.8,
            'circle-stroke-width': 2,
            'circle-stroke-color': theme.colors.orange[200],
          }}
        />
      </Source>

      {!selected.layerType &&
        selected.lat &&
        selected.lng &&
        selected.tab === TAB_LOCATIONS && (
          <>
            <Popup
              latitude={selected.lat}
              longitude={selected.lng}
              closeButton
              onClose={() =>
                unsetSelected({
                  tab: TAB_LOCATIONS,
                })
              }
              closeOnClick={false}
              captureClick
              anchor="bottom"
            >
              <div>
                <LocationForm />
              </div>
            </Popup>
          </>
        )}
    </>
  );
};

export default observer(LocationsLayer);

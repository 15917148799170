import React from 'react';
import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';

interface SimpleSliderProps extends Omit<SliderProps, 'display'> {
  display?: string | number;
}

const SimpleSlider = React.forwardRef<HTMLDivElement, SimpleSliderProps>(
  ({ display, ...props }, _ref) => {
    return (
      <Box display="flex">
        <Slider {...props} ref={_ref}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <SliderThumb />
        </Slider>
        {display && (
          <Box display="inline-block" width={70} textAlign="right">
            <Text>{display}</Text>
          </Box>
        )}
      </Box>
    );
  }
);

export default SimpleSlider;

/* eslint-disable camelcase */
import React, { useContext, useRef } from 'react';
import firebase from 'firebase/app';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import InteractiveMap, { ViewportProps } from 'react-map-gl';
import axios from 'axios';
import { transparentize } from 'polished';
import { dataUrl } from 'data';
import { AuthStore } from 'auth';
import { AppStore } from 'appStore';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import { isLocationQuery, LocationQuery } from 'DataCachingLayer';

export const CUSTOM_MAPBOX_STYLE_URL =
  'mapbox://styles/daveneon/ckkyxlh8a09xb17pexdilto51';

const kelowna = {
  latitude: 49.888,
  longitude: -119.496,
  zoom: 12,
};

interface HasLatLngZoom {
  latitude: number;
  longitude: number;
  zoom?: number;
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

const context = React.createContext<AppContext | null>(null);

export interface ViewOptions {
  hour: number;
  dataField?: string;
  month: string;
  day: string;
  dow: string;
  region: string;
  lat: number;
  lng: number;
  minLat?: number;
  maxLat?: number;
  minLng?: number;
  maxLng?: number;
  isFlying: boolean;
  allDays: boolean;
  allHours: boolean;
  showWork: boolean;
  showHome: boolean;
  showStores: boolean;
  showCandidateLocations: boolean;
  monthIndex: number;
  heatmapIntensity: number;
  mapStyle: string;
  colourHeatMap1: string;
  colourHeatMap2: string;
  colourHeatMap3: string;
  colourHeatMap4: string;
  colourHeatMap5: string;
  workMarkerHigh: string;
  workMarkerLow: string;
  homeMarkerHigh: string;
  homeMarkerLow: string;
  storeColour: string;
  locationColour: string;
  activeLocationColour: string;
}

export const defaultColours = {
  colourHeatMap1: 'rgba(29,72,119,0)',
  colourHeatMap2: 'rgba(27,138,90,.7)',
  colourHeatMap3: 'rgba(251,176,33,.7)',
  colourHeatMap4: 'rgba(246,136,56,.7)',
  colourHeatMap5: 'rgba(238,62,50,.7)',

  workMarkerHigh: transparentize(0.1, '#D4628D'),
  workMarkerLow: transparentize(0.7, '#D4628D'),
  homeMarkerHigh: transparentize(0.1, '#5bbaf6'),
  homeMarkerLow: transparentize(0.7, '#5bbaf6'),
  storeColour: '#38A169',
  locationColour: '#ff9a2e',
  activeLocationColour: 'rgba(238,62,50,.7)',
};

interface SelectedPlace {
  storeName: string;
  tab: number;
  address: string;
  city: string;
  id: string;
  status: string;
  lat: number;
  lng: number;
  radius: number;
  layerType: 'cannabis-store' | 'candidate-location' | null;
}

export type MapType = 'heatmap' | 'sample' | 'score' | 'transactions';

const initialSelectedState: SelectedPlace = {
  storeName: '',
  tab: 0,
  address: '',
  city: '',
  id: '',
  status: '',
  lat: 0,
  lng: 0,
  radius: 100,
  layerType: null,
};

export class AppContext {
  months: string[] = [];

  authStore: AuthStore;

  appStore: AppStore;

  async getMonthOptions() {
    const r = await axios.get(dataUrl('months'));
    this.months = r.data.months as string[];
  }

  viewOptions: ViewOptions = {
    hour: 1,
    dataField: 'p',
    month: '',
    region: 'bcint',
    dow: '1',
    day: '1',
    lat: 0,
    lng: 0,
    allDays: true,
    allHours: true,
    showWork: false,
    showHome: false,
    showStores: true,
    showCandidateLocations: true,
    isFlying: false,
    monthIndex: 0,
    heatmapIntensity: 5,
    mapStyle: CUSTOM_MAPBOX_STYLE_URL,
    ...defaultColours,
  };

  selected: SelectedPlace = {
    ...initialSelectedState,
  };

  map: InteractiveMap | null = null;

  mapType?: MapType = undefined;

  setMapType(t?: MapType) {
    this.mapType = t;
  }

  viewport: Partial<ViewportProps> | null = {
    ...kelowna,
    zoom: 10,
    ...JSON.parse(window.localStorage.getItem('lastLocation') || '{}'),
    pitch: 20,
    bearing: 0,
  };

  setViewport(v: Partial<ViewportProps> | null) {
    window.localStorage.setItem('lastLocation', JSON.stringify(v));
    this.viewport = v;
  }

  getLocationQuery() {
    const { minLat, maxLng, minLng, maxLat, lat, lng } = this.viewOptions;
    return new URLSearchParams({
      minLat: `${minLat}`,
      maxLng: `${maxLng}`,
      minLng: `${minLng}`,
      maxLat: `${maxLat}`,
      lat: `${lat}`,
      lng: `${lng}`,
    }).toString();
  }

  setMap(map: InteractiveMap | null) {
    if (map && !this.map) {
      this.map = map;
      const m = map.getMap();
      let debounce: number;
      const geocoder = new MapboxGeocoder({
        flyTo: false,
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        mapboxgl,
      });
      geocoder.on('result', (e) => {
        this.flyTo({
          latitude: e.result.center[1],
          longitude: e.result.center[0],
          zoom: 12,
        });
      });
      m.addControl(geocoder);

      m.on('moveend', (e) => {
        if (e.fly && e.location) {
          action(() => {
            this.viewOptions.isFlying = false;
          })();
          this.setViewport({
            ...this.viewport,
            ...(e.location as Partial<ViewportProps>),
          });
          window.localStorage.setItem(
            'lastLocation',
            JSON.stringify(this.viewport)
          );
        }

        clearTimeout(debounce);

        debounce = window.setTimeout(
          action(() => {
            const bounds = m.getBounds();
            const { lat, lng } = bounds.getCenter();
            this.viewOptions.lat = lat;
            this.viewOptions.lng = lng;
            const { lat: minLat, lng: minLng } = bounds.getSouthWest();
            const { lat: maxLat, lng: maxLng } = bounds.getNorthEast();
            this.viewOptions.maxLat = maxLat;
            this.viewOptions.minLat = minLat;
            this.viewOptions.minLng = minLng;
            this.viewOptions.maxLng = maxLng;
          }),
          1000
        );
      });
    }
  }

  flyTo(l: HasLatLngZoom) {
    if (this.map) {
      const m = this.map.getMap();
      action(() => {
        this.viewOptions.isFlying = true;
      })();
      m.flyTo(
        { center: [l.longitude, l.latitude], zoom: l.zoom || 10 },
        { fly: true, location: l }
      );
    }
  }

  unsetSelected(override: Partial<SelectedPlace> = {}) {
    Object.assign(this.selected, initialSelectedState, override);
  }

  constructor() {
    this.viewOptions = makeAutoObservable(this.viewOptions);
    this.selected = makeAutoObservable(this.selected);
    this.appStore = makeAutoObservable(new AppStore());
    makeObservable(this, {
      setMap: action,
      map: observable,
      viewport: observable,
      setViewport: action,
      mapType: observable,
      setMapType: action,
      months: observable,
      getMonthOptions: action,
      unsetSelected: action.bound,
    });
    this.getMonthOptions();
    this.authStore = new AuthStore();
  }

  get locationQuery(): LocationQuery {
    if (!isLocationQuery(this.viewOptions)) {
      // This should never happen
      throw new Error('Missing location query');
    }

    const { minLat, maxLng, minLng, maxLat, lat, lng } = this.viewOptions;
    return {
      minLat,
      maxLng,
      minLng,
      maxLat,
      lat,
      lng,
    };
  }
}

const AppContextProvider: React.FC = ({ children }) => {
  const contextValue = useRef(new AppContext());

  return (
    <context.Provider value={contextValue.current}>{children}</context.Provider>
  );
};

export function useApplicationContext(): AppContext {
  const appContext = useContext(context);
  if (!appContext) {
    throw new Error(
      'Unable to find application context, did you specify the AppContextProvider?'
    );
  }
  return appContext;
}

export function useAppStore() {
  const ctx = useApplicationContext();
  return ctx.appStore;
}

export default AppContextProvider;

export const stores = [
  {
    name: 'Abbotsford',
    storeCode: 'ABBBC001',
    lat: 49.0370571,
    lng: -122.2300655,
  },
  {
    name: 'Abby Future',
    storeCode: 'ABBBC002',
    lat: 49.0504377,
    lng: -122.3044697,
  },
  { name: 'Comox', storeCode: 'COMBC001', lat: 49.6880303, lng: -124.9382442 },
  {
    name: 'Time Square',
    storeCode: 'CMXBC001',
    lat: 49.6880303,
    lng: -124.9382442,
  },
  {
    name: 'Edmonton Commons Walmart',
    storeCode: 'EDAB001',
    lat: 53.5461245,
    lng: -113.4938229,
  },
  {
    name: 'Airport Meta',
    storeCode: 'KELBC003',
    lat: 49.9511758,
    lng: -119.3874561,
  },
  {
    name: 'Clement',
    storeCode: 'KELBC005',
    lat: 49.8942598,
    lng: -119.4830415,
  },
  {
    name: 'Compass',
    storeCode: 'KELBC001',
    lat: 49.8824351,
    lng: -119.4439487,
  },
  {
    name: 'Pandosy',
    storeCode: 'KELBC004',
    lat: 49.8665047,
    lng: -119.4915798,
  },
  {
    name: 'Springfield',
    storeCode: 'KELBC003',
    lat: 49.8767565,
    lng: -119.442652,
  },
  {
    name: 'Lake Country',
    storeCode: 'LAKBC001',
    lat: 50.0204022,
    lng: -119.4044711,
  },
  {
    name: 'Goldstream',
    storeCode: 'VICBC008',
    lat: 48.4495689,
    lng: -123.5007458,
  },
  {
    name: 'Aulds Road',
    storeCode: 'NANBC002',
    lat: 49.2338978,
    lng: -124.0501955,
  },
  {
    name: 'Nanaimo North Mall',
    storeCode: 'NANBC001',
    lat: 49.21877,
    lng: -124.028576,
  },
  {
    name: 'Turner Truffles',
    storeCode: 'NAMBC006',
    lat: 49.2259385,
    lng: -124.0370888,
  },
  {
    name: 'PG Parkwood Place Meta',
    storeCode: 'PGBC001',
    lat: 53.9106759,
    lng: -122.7598047,
  },
  {
    name: '3rd Street Sidney',
    storeCode: 'SIDBC001',
    lat: 48.6483952,
    lng: -123.3980792,
  },
  {
    name: 'St. Alberta Walmart',
    storeCode: 'SAAB001',
    lat: 53.653938,
    lng: -113.6293586,
  },
  {
    name: 'Sylvan Walmart',
    storeCode: 'SYLAB',
    lat: 52.3065443,
    lng: -114.0973312,
  },
  { name: 'Yonge', storeCode: 'TORON001', lat: 43.6656109, lng: -79.3847113 },
  {
    name: 'Dunbar Meta',
    storeCode: 'VANBC005',
    lat: 49.2347729,
    lng: -123.1852952,
  },
  {
    name: 'Kitsilano',
    storeCode: 'VANBC002',
    lat: 49.2679572,
    lng: -123.1492212,
  },
  {
    name: 'Main Street',
    storeCode: 'VANBC001',
    lat: 49.2466496,
    lng: -123.1010098,
  },
  {
    name: 'Marpole Dutch',
    storeCode: 'VANBC000',
    lat: 49.210216,
    lng: -123.1407449,
  },
  {
    name: 'Robson Dutch',
    storeCode: 'VANBC004',
    lat: 49.2857439,
    lng: -123.1262864,
  },
  {
    name: '546 Yates',
    storeCode: 'VICBC005',
    lat: 48.4270829,
    lng: -123.3687055,
  },
  {
    name: '570 Yates',
    storeCode: 'VICBC002',
    lat: 48.4267254,
    lng: -123.3681622,
  },
  { name: 'Alpha', storeCode: 'VICBC001', lat: 48.4468699, lng: -123.3728013 },
  {
    name: 'Colwood Corner',
    storeCode: 'VICBC003',
    lat: 48.4416644,
    lng: -123.4683426,
  },
  {
    name: 'Pacificanna',
    storeCode: 'VICBC004',
    lat: 48.4308391,
    lng: -123.3270662,
  },
  {
    name: 'Tuscany Village',
    storeCode: 'VICBC006',
    lat: 48.469338,
    lng: -123.331276,
  },
];

import React from 'react';
import { useMapData } from 'useMapData';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useApplicationContext, ViewOptions } from 'AppContextProvider';
import { observer } from 'mobx-react';

const MAX_ZOOM_LEVEL = 20;

const heatmapLayer = (options: ViewOptions): LayerProps => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'circle',
  id: 'cannabis-stores-layer',
  layout: {
    visibility: options.showStores ? 'visible' : 'none',
  },
  paint: {
    'circle-color': options.storeColour,
    'circle-radius': 5,
    'circle-opacity': 0.8,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#9AE6B4',
  },
});

const CannabisStores: React.FC = () => {
  const { url } = useMapData('stores');
  const ctx = useApplicationContext();

  return (
    <Source id="cannabis-stores" type="geojson" data={url}>
      <Layer {...heatmapLayer(ctx.viewOptions)} />
    </Source>
  );
};

export default observer(CannabisStores);

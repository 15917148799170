/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
} from '@chakra-ui/react';
import { useApplicationContext, useAppStore } from 'AppContextProvider';
import { useForm } from 'react-hook-form';
import { TAB_LOCATIONS } from './DetailsPanel';

interface LocationFormValues {
  name?: string;
}

const LocationForm: React.FC = () => {
  const { register, handleSubmit, formState } = useForm<LocationFormValues>();
  const { viewport, selected, unsetSelected } = useApplicationContext();
  const appStore = useAppStore();
  const { docs } = appStore.locations;
  const { isSubmitting, isDirty } = formState;

  const onSubmit = async ({ name }: LocationFormValues) => {
    if (name && viewport) {
      try {
        await appStore.createLocation(
          name,
          selected.lat,
          selected.lng,
          viewport.zoom
        );

        // HACK: The Popup does not seem to respect the captureClick property
        // on attempts after the form is submitted more than once, causing the
        // "add location" form to appear again immediately after clicking its submit button.
        // TODO: Investigate the cause of this.
        window.setTimeout(() => {
          unsetSelected({
            tab: TAB_LOCATIONS,
          });
        }, 200);
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (!docs) {
    return null;
  }

  return (
    <Box as="form" minWidth="300px" onSubmit={handleSubmit(onSubmit)}>
      <HStack spacing={2}>
        <FormControl>
          <FormLabel fontWeight="bold" fontSize="md">
            Add a Candidate Location
          </FormLabel>
          <Input
            variant="underline"
            placeholder="Location name"
            type="text"
            ref={register}
            name="name"
            autoFocus
          />
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="lightblue"
          disabled={isSubmitting || !isDirty}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </HStack>
    </Box>
  );
};

export default LocationForm;

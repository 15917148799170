/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Text,
  Badge,
  BadgeProps,
  Heading,
} from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import Axios from 'axios';
import { dataUrl } from 'data';
import { useAutorun } from 'useAutorun';
import PointDetailResults, { ReportResponse } from 'PointDetailResults';
import { action } from 'mobx';

function useReport(lat: number, lng: number) {
  const [results, setResults] = useState<ReportResponse | undefined>();
  const [loading, setLoading] = useState(false);

  const fetch = async (radius: string) => {
    setLoading(true);
    const res = await Axios.get<ReportResponse>(
      dataUrl(`report/${lat}/${lng}?radius=${radius}`)
    );
    setLoading(false);
    const { data } = res;
    data.lat = lat;
    data.lng = lng;

    // Default empty sets to a score of 0
    if (!data.monthly || !data.monthly.length) {
      data.monthly = [
        {
          totalDevices: 0,
          totalPoints: 0,
          totalCannabisDevices: 0,
          totalCannabisPoints: 0,
          bucket0: 0,
          bucket1: 0,
          bucket2: 0,
          userScore: 0,
          estOfSale: 0,
          score: 0,
          month: 'All Months',
        },
      ];
    }
    setResults(data);
  };

  const clearResults = () => setResults(undefined);
  return { results, loading, fetch, clearResults };
}

interface LocationStatusBadgeProps extends BadgeProps {
  status: string;
}

const LocationStatusBadge: React.FC<LocationStatusBadgeProps> = ({
  status,
  ...props
}) => {
  let colorScheme;
  switch (status) {
    case 'Active':
      colorScheme = 'green';
      break;
    case 'Success':
      colorScheme = 'lightblue';
      break;
    case 'Closed':
      colorScheme = 'red';
      break;
    default:
      colorScheme = 'gray';
  }

  return (
    <Badge colorScheme={colorScheme} variant="solid" {...props}>
      {status}
    </Badge>
  );
};

const TabPanelHeading: React.FC = () => (
  <Heading size="md" color="white" mb={4}>
    Location Report
  </Heading>
);

const PointDetails: React.FC = () => {
  const { selected } = useApplicationContext();
  const { loading, results, fetch, clearResults } = useReport(
    selected.lat,
    selected.lng
  );

  useAutorun(() => {
    if (results) {
      if (selected.lat !== results?.lat) {
        clearResults();
      }
    }
  });

  const handleRun = () => {
    fetch(`${selected.radius}`);
  };
  if (selected.lat === 0) {
    return (
      <>
        <TabPanelHeading />
        <Text>Begin by selecting a point on the map.</Text>
      </>
    );
  }

  const isStoreSelected = !!selected.storeName;

  return (
    <Box>
      <TabPanelHeading />
      {isStoreSelected && (
        <Box mb={4}>
          <Text fontSize="xl" color="white">
            {selected.storeName}
          </Text>
          <Box lineHeight="shorter">
            <Text>{selected.address}</Text>
            <Text>{selected.city}</Text>
          </Box>
          <LocationStatusBadge status={selected.status} />
        </Box>
      )}

      <Box fontSize="xs">
        <Text color="white" fontWeight="bold">
          Selected Coordinates
        </Text>

        <Text isTruncated>
          {selected.lat} {selected.lng}
        </Text>
      </Box>

      <Flex my={6}>
        <FormControl>
          <FormLabel color="white" fontWeight="bold">
            Radius
          </FormLabel>
          <Select
            onChange={action((e: React.ChangeEvent<HTMLSelectElement>) => {
              selected.radius = Number(e.target.value);
            })}
            defaultValue={selected.radius}
          >
            <option value="50">50m</option>
            <option value="100">100m</option>
            <option value="250">250m</option>
            <option value="500">500m</option>
            <option value="1000">1km</option>
            <option value="2000">2km</option>
            <option value="5000">5km</option>
          </Select>
        </FormControl>
        <Box ml={4} alignSelf="flex-end">
          <Button
            colorScheme="lightblue"
            type="button"
            onClick={handleRun}
            isLoading={loading}
          >
            Run
          </Button>
        </Box>
      </Flex>
      <PointDetailResults results={results} />
    </Box>
  );
};

export default observer(PointDetails);

/* eslint-disable camelcase */
// http://127.0.0.1:5001/predictor-78c77/us-central1/data/report

import React, { useState } from 'react';
import { Button, Spinner, Icon } from '@chakra-ui/react';
import { useAppStore } from 'AppContextProvider';
import Axios from 'axios';
import { observer } from 'mobx-react';
import { dataUrl } from 'data';
import { HiDownload } from 'react-icons/hi';

interface ReportResponse {
  location_name: string;
  month: { value: string };
  total_cannabis_points: number;
  total_points: number;
  score: number;
  user_score: number;
  bucket_0: number;
  bucket_1: number;
  bucket_2: number;
  est_of_sale: number;
  compare?: {
    is_closest_option: number;
    closer_stores: number;
  };
}

const ExportReport: React.FC = () => {
  const appStore = useAppStore();
  const [exporting, setExporting] = useState(false);

  if (!appStore.locations.docs || appStore.locations.docs.length === 0) {
    return null;
  }
  const handleClick = async () => {
    setExporting(true);
    const response = await Axios.post<ReportResponse[]>(
      dataUrl('report'),
      appStore.locations.docs?.map((d) => ({
        lat: d.data().latitude,
        lng: d.data().longitude,
        name: d.data().name,
      }))
    );
    const element = document.createElement('a');

    const file = new Blob(
      [
        '"location","month","cannabis_points","total_points","bucket_0","bucket_1","bucket_2","est_of_sale","user_score","is_closest_option","has_closer_stores","score"\n',
        ...response.data.map((r) => {
          return `"${r.location_name.replace(
            '"',
            '\\"'
          )}","${r.month.value.substr(0, 10)}","${r.total_cannabis_points}","${
            r.total_points
          }","${r.bucket_0}","${r.bucket_1}","${r.bucket_2}","${
            r.est_of_sale
          }","${r.user_score}","${r.compare?.is_closest_option}","${
            r.compare?.closer_stores
          }","${r.score}"\n`;
        }),
      ],
      {
        type: 'text/plain',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = 'export.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setExporting(false);
  };
  return (
    <Button
      variant="link"
      size="sm"
      colorScheme="lightblue"
      fontWeight="normal"
      onClick={handleClick}
      disabled={exporting}
    >
      {exporting ? (
        <Spinner size="sm" mr={1} />
      ) : (
        <Icon as={HiDownload} boxSize={4} mr={1} />
      )}
      Score report
    </Button>
  );
};

export default observer(ExportReport);

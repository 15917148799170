/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
import { Box, Heading, Text } from '@chakra-ui/react';
import { useApplicationContext } from 'AppContextProvider';
import CannabisStores from 'CannabisStores';
import DetailsPanel, { TAB_DETAILS, TAB_LOCATIONS } from 'DetailsPanel';
import DeviceLocationMap from 'DeviceLocationMap';
import LocationsLayer from 'LocationsLayer';
import MapHeatMap from 'MapHeatMap';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import RadiusMarker from 'RadiusMarker';
import React, { useState } from 'react';
import ReactMapGL, {
  NavigationControl,
  Popup,
  ScaleControl,
  PointerEvent,
  Layer,
  Source,
} from 'react-map-gl';
import ScoreHeatMap from 'ScoreHeatMap';
import TransactionsHeatMap from 'TransactionsHeatMap';

// A fake layer with no real data that is only referenced by other Layer components
// to insert themselves before to control z-index.
const FakeLayer1: React.FC = () => (
  <Source
    type="geojson"
    data={{
      type: 'FeatureCollection',
      features: [],
    }}
  >
    <Layer id="fake-layer-1" paint={{}} type="fill" />
  </Source>
);

const FakeLayer2: React.FC = () => (
  <Source
    type="geojson"
    data={{
      type: 'FeatureCollection',
      features: [],
    }}
  >
    <Layer id="fake-layer-2" paint={{}} type="fill" />
  </Source>
);

interface PopUp {
  latitude: number;
  longitude: number;
  content?: string;
  layerType?: 'cannabis-store' | 'candidate-location';
}

const Map: React.FC = () => {
  const ctx = useApplicationContext();
  const { selected } = ctx;

  const [popup, setPopup] = useState<PopUp | undefined>(undefined);
  const handleClick = action((e: PointerEvent) => {
    selected.lat = e.lngLat[1];
    selected.lng = e.lngLat[0];
    const stores = e.features.filter((f) => f.source === 'cannabis-stores');
    const candidates = e.features.filter(
      (f) => f.source === 'candidate-locations'
    );
    if (candidates.length > 0) {
      const candidate = candidates[0];
      setPopup({
        latitude: e.lngLat[1],
        longitude: e.lngLat[0],
        content: candidate.properties.name,
        layerType: 'candidate-location',
      });
      selected.storeName = '';
      selected.address = '';
      selected.city = '';
      selected.id = '';
      selected.status = '';
      selected.tab = TAB_LOCATIONS;
      selected.layerType = 'candidate-location';
    } else if (stores.length > 0) {
      const store = stores[0];
      setPopup({
        latitude: e.lngLat[1],
        longitude: e.lngLat[0],
        content: store.properties.store_name,
        layerType: 'cannabis-store',
      });
      const { address, city, id, status, store_name } = store.properties;
      selected.storeName = store_name;
      selected.address = address;
      selected.city = city;
      selected.id = id;
      selected.status = status;
      selected.tab = TAB_DETAILS;
      selected.layerType = 'cannabis-store';
    } else {
      setPopup(undefined);
      selected.storeName = '';
      selected.address = '';
      selected.city = '';
      selected.id = '';
      selected.status = '';
      selected.layerType = null;
    }
  });
  const showHeatMap = ctx.mapType === 'heatmap';
  const showScoreMap = ctx.mapType === 'score';
  const showTxMap = ctx.mapType === 'transactions';

  return (
    <Box display="flex">
      <ReactMapGL
        ref={(map) => {
          ctx.setMap(map);
        }}
        width="100%"
        height="100vh"
        {...ctx.viewport}
        mapStyle={ctx.viewOptions.mapStyle}
        onViewportChange={(v) =>
          ctx.setViewport({
            ...ctx.viewport,
            latitude: v.latitude,
            longitude: v.longitude,
            zoom: v.zoom,
            pitch: v.pitch,
            bearing: v.bearing,
          })
        }
        onClick={handleClick}
        getCursor={() => 'pointer'}
      >
        <div style={{ position: 'absolute', bottom: 100, left: 20 }}>
          <ScaleControl maxWidth={100} unit="metric" />
        </div>
        <div style={{ position: 'absolute', bottom: 30, right: 30 }}>
          <NavigationControl showCompass={false} />
        </div>
        <FakeLayer1 />
        {showHeatMap && <MapHeatMap />}
        {showScoreMap && <ScoreHeatMap />}
        {showTxMap && <TransactionsHeatMap />}
        <FakeLayer2 />
        <DeviceLocationMap />
        <CannabisStores />
        <LocationsLayer />
        {!ctx.viewOptions.isFlying && (
          <>
            {popup && (
              <Popup
                latitude={popup.latitude}
                longitude={popup.longitude}
                closeButton
                closeOnClick={false}
                captureClick
                offsetTop={-8}
                onClose={() => setPopup(undefined)}
                anchor="bottom"
              >
                <div>
                  {popup.layerType === 'cannabis-store' && (
                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                      Cannabis Store
                    </Text>
                  )}
                  {popup.layerType === 'candidate-location' && (
                    <Text fontSize="sm" fontWeight="bold" color="orange.600">
                      Candidate Location
                    </Text>
                  )}
                  <Heading fontSize="lg" lineHeight="short">
                    {popup.content}
                  </Heading>
                </div>
              </Popup>
            )}
            <RadiusMarker />
          </>
        )}
      </ReactMapGL>
      <DetailsPanel />
    </Box>
  );
};

export default observer(Map);

import { Polygon } from 'geojson';

export function createGeoJSONCircle(
  latitude: number,
  longitude: number,
  radiusInKm: number
) {
  const coords = {
    latitude,
    longitude,
  };
  const points = 64;
  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return { type: 'Polygon', coordinates: [ret] } as Polygon;
}

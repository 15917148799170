import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useApplicationContext, AppContext } from 'AppContextProvider';
import { observer } from 'mobx-react';
import { dataCache } from './DataCachingLayer';

const MAX_ZOOM_LEVEL = 17;
const CHANGE_GEOHASH_AT_ZOOM_LEVEL = 13;

const heatmapLayer = (ctx: AppContext): LayerProps => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  beforeId: 'fake-layer-1',
  layout: {
    visibility: ctx.mapType === 'transactions' ? 'visible' : 'none',
  },
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'total'],
      0,
      0,
      2000,
      0.3,
      40000,
      0.5,
      80000,
      0.8,
      100000,
      1.5,
      160000,
      2,
    ],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      0.1,
      8,
      0.3,
      13,
      2,
      18,
      3,
    ],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      ctx.viewOptions.colourHeatMap1,
      0.25,
      ctx.viewOptions.colourHeatMap2,
      0.5,
      ctx.viewOptions.colourHeatMap3,
      0.75,
      ctx.viewOptions.colourHeatMap4,
      1.0,
      ctx.viewOptions.colourHeatMap5,
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      50,
      11,
      50,
      12,
      60,
      13,
      130,
      14,
      250,
      15,
      400,
      16,
      600,
      18,
      2000,
    ],
    // Transition from heatmap to circle layer by zoom level
    // 'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 16, 0],
  },
});

const TransactionHeatMap: React.FC = () => {
  const ctx = useApplicationContext();
  const { lat } = ctx.viewOptions;

  if (lat === 0) {
    return null;
  }

  const layerProps = heatmapLayer(ctx);

  const data = dataCache.data('transactions', ctx.locationQuery, {
    zoomRequeryAreaThreshold: 0.005,
    geohashLevel:
      ctx.viewport?.zoom && ctx.viewport.zoom <= CHANGE_GEOHASH_AT_ZOOM_LEVEL
        ? 6
        : 7,
  });
  return (
    <Source type="geojson" data={data} id="transactions">
      <Layer {...layerProps} id="transactions" />
    </Source>
  );
};

export default observer(TransactionHeatMap);

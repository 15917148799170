import React from 'react';
import { Box, Checkbox, Stack, Heading } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { action } from 'mobx';
import { useForm } from 'react-hook-form';
import { useApplicationContext } from 'AppContextProvider';

interface MapSettingsForm {
  showWork: boolean;
  showHome: boolean;
  showStores: boolean;
  showCandidateLocations: boolean;
}

const LayerToggles: React.FC = () => {
  const { viewOptions } = useApplicationContext();
  const { register, watch } = useForm<MapSettingsForm>({
    defaultValues: { ...viewOptions },
  });

  const handleChange = action(() => {
    const { showWork, showHome, showStores, showCandidateLocations } = watch();
    viewOptions.showWork = showWork;
    viewOptions.showHome = showHome;
    viewOptions.showStores = showStores;
    viewOptions.showCandidateLocations = showCandidateLocations;
  });

  return (
    <Box>
      <form onChange={handleChange}>
        <Heading size="md" color="white" mb={4}>
          Map Layers
        </Heading>
        <Stack>
          <Checkbox
            colorScheme="lightblue"
            defaultIsChecked
            ref={register}
            name="showStores"
          >
            Cannabis stores
          </Checkbox>
          <Checkbox
            colorScheme="lightblue"
            defaultIsChecked
            ref={register}
            name="showCandidateLocations"
          >
            Candidate locations
          </Checkbox>
          <Checkbox
            colorScheme="lightblue"
            defaultIsChecked
            ref={register}
            name="showWork"
          >
            Work locations
          </Checkbox>
          <Checkbox
            colorScheme="lightblue"
            defaultIsChecked
            ref={register}
            name="showHome"
          >
            Home locations
          </Checkbox>
        </Stack>
      </form>
    </Box>
  );
};

export default observer(LayerToggles);

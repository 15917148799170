import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { FeatureCollection } from 'geojson';

const AREA_MULTIPLIER = 5;
const ZOOM_PERCENT_REQUERY_THRESHOLD = 1;
const ZOOM_AREA_REQUERY_THRESHOLD = 1.5; // Approximately 7000 km^2;

type QueryOptions = {
  areaMultiplier?: number;
  zoomRequeryThresholdPercent?: number;
  zoomRequeryAreaThreshold?: number;
  geohashLevel?: number;
};

type Bounds = {
  minLat: number;
  maxLng: number;
  minLng: number;
  maxLat: number;
};

export type LocationQuery = {
  minLat: number;
  maxLng: number;
  minLng: number;
  maxLat: number;
  lat: number;
  lng: number;
  geohashLevel?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLocationQuery(args: any): args is LocationQuery {
  return !!(
    args &&
    args.minLat &&
    args.maxLng &&
    args.minLng &&
    args.maxLat &&
    args.lat &&
    args.lng
  );
}

export class DataCachingLayer {
  private static async _getData(
    path: string,
    query: LocationQuery
  ): Promise<FeatureCollection> {
    const { minLat, maxLng, minLng, maxLat, lat, lng, geohashLevel } = query;

    const url = `${
      process.env.REACT_APP_FUNCTIONS_ENDPOINT
    }${path}?${new URLSearchParams({
      minLat: `${minLat}`,
      maxLng: `${maxLng}`,
      minLng: `${minLng}`,
      maxLat: `${maxLat}`,
      lat: `${lat}`,
      lng: `${lng}`,
      geohashLevel: geohashLevel ? `${geohashLevel}` : '',
    }).toString()}`;

    const res = await axios.get<FeatureCollection>(url, {
      baseURL: process.env.REACT_APP_FUNCTIONS_ENDPOINT,
    });

    return res.data;
  }

  public static isBiggerBounds(bound1: Bounds, bound2?: Bounds): boolean {
    if (!bound2) {
      return true;
    }

    return (
      bound2.maxLat <= bound1.maxLat &&
      bound2.minLat >= bound1.minLat &&
      bound2.maxLng <= bound1.maxLng &&
      bound2.minLng >= bound1.minLng
    );
  }

  // Calculate the rough area of a lat, lng. Just using a rectangle for now.
  public static calculateArea(bounds: Bounds): number {
    return (bounds.maxLat - bounds.minLat) * (bounds.maxLng - bounds.minLng);
  }

  constructor() {
    makeObservable(this);
  }

  @observable
  private _cached: Record<string, FeatureCollection> = {};

  @observable
  private _bounds: Record<string, [loading: boolean, bounds: Bounds]> = {};

  @computed
  get loading(): boolean {
    for (const [, [loading]] of Object.entries(this._bounds)) {
      if (loading) {
        return true;
      }
    }

    return false;
  }

  public isInBounds(path: string, query: LocationQuery): boolean {
    if (!this._bounds[path]) {
      return false;
    }

    const bounds = this._bounds[path][1];
    return DataCachingLayer.isBiggerBounds(bounds, query);
  }

  public isPastAreaThreshold(
    path: string,
    query: LocationQuery,
    zoomRequeryThresholdPercent: number = ZOOM_PERCENT_REQUERY_THRESHOLD,
    zoomRequeryAreaThreshold: number = ZOOM_AREA_REQUERY_THRESHOLD
  ): boolean {
    if (!this.isInBounds(path, query)) {
      return false;
    }

    // Issue a re-query if the user is substantially zoomed in and the original area was too large to cover everything
    const isZoomedInLots =
      (DataCachingLayer.calculateArea(query) /
        DataCachingLayer.calculateArea(this._bounds[path][1])) *
        100 <
      zoomRequeryThresholdPercent;
    const isOriginalAreaLarge =
      DataCachingLayer.calculateArea(this._bounds[path][1]) >
      zoomRequeryAreaThreshold;
    return isZoomedInLots && isOriginalAreaLarge;
  }

  @action
  private _ensureData(path: string): void {
    if (!this._cached[path]) {
      this._cached[path] = {
        type: 'FeatureCollection',
        features: [],
      };
    }
  }

  private _data(path: string): FeatureCollection {
    this._ensureData(path);
    return this._cached[path] as FeatureCollection;
  }

  @action
  private _setData(path: string, data: FeatureCollection): void {
    this._cached[path] = data;
  }

  @action
  private _updateBounds(path: string, loading: boolean, bounds?: Bounds): void {
    const newBounds = bounds ?? this._bounds[path]?.[1];
    if (!newBounds) {
      throw new Error('Missing boundaries');
    }

    this._bounds[path] = [loading, newBounds];
  }

  @action
  private _updateData(
    path: string,
    query: LocationQuery,
    areaMultiplier: number = AREA_MULTIPLIER
  ): void {
    if (this._bounds[path]?.[0] && this.isInBounds(path, query)) {
      // If we already have a query loading that covers these bounds, we can ignore this one
      return;
    }

    // Issue a query for a much large area to handle zooming and traversals
    const x = query.maxLng - query.minLng;
    const y = query.maxLat - query.minLat;
    const delta = Math.min(
      (-1 * (x + y) +
        Math.sqrt((x + y) ** 2 + 4 * x * y * (areaMultiplier - 1))) /
        4,
      2
    );
    const bounded: LocationQuery = {
      ...query,
      minLat: Math.max(query.minLat - delta, -90),
      maxLat: Math.min(query.maxLat + delta, 90),
      minLng: Math.max(query.minLng - delta, -180),
      maxLng: Math.min(query.maxLng + delta, 180),
    };

    this._updateBounds(path, true, bounded);
    DataCachingLayer._getData(path, bounded)
      .then((data) => {
        if (DataCachingLayer.isBiggerBounds(bounded, this._bounds[path]?.[1])) {
          // Make sure we aren't resetting a loading state that has replaced this one. Otherwise ignore the response
          // and wait for the other request to fulfill.
          this._updateBounds(path, false);
          this._setData(path, data);
        }
      })
      .catch((err) => {
        console.error('Request for data has failed: ', err);
        if (DataCachingLayer.isBiggerBounds(bounded, this._bounds[path]?.[1])) {
          // Make sure we aren't resetting a loading state that has replaced this one
          this._updateBounds(path, false);
        }
      });
  }

  public data(
    path: string,
    query: LocationQuery,
    opt: QueryOptions = {}
  ): FeatureCollection {
    if (
      !this.isInBounds(path, query) ||
      this.isPastAreaThreshold(
        path,
        query,
        opt.zoomRequeryThresholdPercent,
        opt.zoomRequeryAreaThreshold
      )
    ) {
      setImmediate(() =>
        this._updateData(
          path,
          {
            ...query,
            geohashLevel: opt.geohashLevel,
          },
          opt.areaMultiplier
        )
      );
    }

    return this._data(path);
  }
}

export const dataCache = new DataCachingLayer();

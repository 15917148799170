import React, { useState } from 'react';
import {
  Box,
  Stack,
  Text,
  Heading,
  Flex,
  FlexProps,
  Collapse,
  Icon,
  Button,
} from '@chakra-ui/react';
import { HiPlusCircle, HiMinusCircle } from 'react-icons/hi';

const ResultRow: React.FC<FlexProps> = (props) => (
  <Flex justifyContent="space-between" {...props} />
);

interface MonthResult {
  totalDevices: number;
  totalPoints: number;
  totalCannabisDevices: number;
  totalCannabisPoints: number;
  userScore: number;
  estOfSale: number;
  score: number;
  bucket0: number;
  bucket1: number;
  bucket2: number;
  month: string;
}
export interface ReportResponse {
  monthly: MonthResult[];
  lat: number;
  lng: number;
}

interface PointDetailResultsProps {
  results?: ReportResponse;
}

const PointMonthResult: React.FC<{ monthResult: MonthResult }> = ({
  monthResult,
}) => {
  const [detail, setDetail] = useState(false);

  return (
    <Box p={4} bgColor="whiteAlpha.100" borderRadius="md">
      <Heading
        as="h3"
        fontSize="inherit"
        lineHeight="inherit"
        color="white"
        mb={1}
      >
        {monthResult.month}
      </Heading>
      <Box fontSize="sm">
        <ResultRow>
          <Text>Total Pings:</Text>
          <Text>{monthResult.totalPoints}</Text>
        </ResultRow>
        <ResultRow>
          <Text>Unique Devices:</Text>
          <Text>{monthResult.totalDevices}</Text>
        </ResultRow>
        <ResultRow>
          <Text>Total Cannabis Pings:</Text>
          <Text>{monthResult.totalCannabisPoints}</Text>
        </ResultRow>
        <ResultRow>
          <Text>Unique Cannabis Devices:</Text>
          <Text>{monthResult.totalCannabisDevices}</Text>
        </ResultRow>
        <ResultRow>
          <Text>Point Estimate of Sale:</Text>
          <Text>
            {(Math.round(monthResult.estOfSale * 1000) / 1000).toFixed(3)}
          </Text>
        </ResultRow>
        <Box>
          <ResultRow color="white" fontWeight="bold">
            <Button
              variant="unstyled"
              type="button"
              display="flex"
              fontSize="inherit"
              height="auto"
              minWidth="auto"
              borderRadius={0}
              _focus={{
                boxShadow: 'none',
              }}
              onClick={() => setDetail(!detail)}
            >
              <Text mr="2px">User Score</Text>
              <Icon
                as={detail ? HiMinusCircle : HiPlusCircle}
                boxSize="inherit"
                color="inherit"
              />
            </Button>
            <Text>{monthResult.userScore}</Text>
          </ResultRow>
          <Collapse in={detail}>
            <Box marginLeft={4}>
              <ResultRow>
                <Text>within 100m:</Text>
                <Text>{monthResult.bucket0}</Text>
              </ResultRow>
              <ResultRow>
                <Text>within 500m:</Text>
                <Text>{monthResult.bucket1}</Text>
              </ResultRow>
              <ResultRow>
                <Text>within 3km:</Text>
                <Text>{monthResult.bucket2}</Text>
              </ResultRow>
            </Box>
          </Collapse>
        </Box>
        <ResultRow color="white" fontWeight="bold">
          <Text>Overall Score</Text>
          <Text>{monthResult.score}</Text>
        </ResultRow>
      </Box>
    </Box>
  );
};

const PointDetailResults: React.FC<PointDetailResultsProps> = ({ results }) => {
  if (!results) {
    return null;
  }
  const rows = results.monthly.map((r) => (
    <PointMonthResult key={r.month} monthResult={r} />
  ));
  return (
    <Stack direction="column" spacing={6}>
      {rows}
    </Stack>
  );
};

export default PointDetailResults;

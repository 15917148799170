/* eslint-disable camelcase */
import React from 'react';
import { Box, Select, Heading } from '@chakra-ui/react';
import {
  useApplicationContext,
  CUSTOM_MAPBOX_STYLE_URL,
} from 'AppContextProvider';
import { observer } from 'mobx-react';
import { action } from 'mobx';

const Colours: React.FC = () => {
  const ctx = useApplicationContext();
  const { viewOptions } = ctx;

  return (
    <Box>
      <form>
        <Box mb={8}>
          <Heading size="md" color="white" mb={2}>
            Map Style
          </Heading>
          <Select
            onChange={(e) => {
              action(() => {
                viewOptions.mapStyle = e.target.value;
              })();
            }}
          >
            <option value={CUSTOM_MAPBOX_STYLE_URL}>Custom</option>
            <option value="mapbox://styles/mapbox/streets-v11">Streets</option>
            <option value="mapbox://styles/mapbox/outdoors-v11">
              Outdoors
            </option>
            <option value="mapbox://styles/mapbox/light-v10">Light</option>
            <option value="mapbox://styles/mapbox/dark-v10">Dark</option>
            <option value="mapbox://styles/mapbox/satellite-v9">
              Satellite
            </option>
            <option value="mapbox://styles/mapbox/satellite-streets-v11">
              Satellite Streets
            </option>
          </Select>
        </Box>
      </form>
    </Box>
  );
};

export default observer(Colours);
